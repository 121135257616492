<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-lg-10">
                <form class="form-inline">
                  <div class="form-group mb-2">
                    <label class="sr-only" for="inputPassword2">Search</label>
                    <input @input="getProcesses(1)" class="form-control" id="inputPassword2" placeholder="Search..." type="search" v-model="filter.search">
                  </div>
                  <div class="form-group mx-sm-2 mb-2">
                    <select @change="getProcesses(1)" class="custom-select" id="status-select" v-model="filter.status">
                      <option :value="null">Filter by Status</option>
                      <option value="InProgress">In Progress</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                  <div class="form-group mb-2">
                    <select @change="getAttributes()" class="custom-select" id="step-select" v-model="selected_step">
                      <option :value="null">Filter by Step</option>
                      <option :value="val" v-for="(val, index) in steps">{{val.name}}</option>
                    </select>
                  </div>
                  <div class="form-group mx-sm-2 mb-2" v-if="selected_step">
                    <select @change="getAttributes()" class="form-control custom-select" id="attribute-select" v-model="selected_attribute">
                      <option :value="null">Filter by Attribute</option>
                      <option :value="val" v-for="(val, index) in attributes">{{val.name}}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mb-2" v-if="selected_attribute">
                    <input :placeholder="'Search '+selected_attribute.name" @input="getProcesses(1)" class="form-control" id="inputAttrVal" type="search" v-model="filter.attribute_value">
                  </div>
                  <div class="form-group mx-sm-2 mb-2">
                    <input @input="getProcesses(1)" class="form-control" id="from_date" name="date" onblur="(this.type='text')" onfocus="(this.type='date')" placeholder="Start Date" type="text" v-model="filter.from_date">
                  </div>

                  <div class="form-group mr-2 mb-2">
                    <input @input="getProcesses(1)" class="form-control" id="to_date" name="date" onblur="(this.type='text')" onfocus="(this.type='date')" placeholder="End Date" type="text" v-model="filter.to_date">
                  </div>
                  <div class="form-group mb-2">
                    <button @click="resetFilter" class="btn btn-outline-secondary" type="button"><i class="mdi mdi-refresh"></i>&nbsp;Reset
                    </button>
                  </div>
                </form>
              </div>
              <div class="col-lg-2" v-if="isFirstStepAttestor && currentUser.roles.includes('ConsignmentAttester')">
                <div class="text-lg-right">
                  <button @click="createProcess" class="btn btn-primary mb-2" type="button"><i class="mdi mdi-plus-circle mr-1"></i> Receive New Consignment
                  </button>
                </div>
              </div><!-- end col-->
            </div>

            <div class="table-responsive" v-if="processes.length>0">
              <table class="table table-centered mb-0">
                <thead class="thead-light">
                  <tr>
                    <th>Consignment ID</th>
                    <th>Truck No.</th>
                    <th>Status</th>
                    <th>Duration</th>
                    <th>Created at</th>
                    <th>Current Step</th>
                      <th v-if="currentUser && currentUser.roles.includes('ConsignmentAttester')">Your Prior Step</th>
                    <th>Transaction</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="cursor:pointer;" v-bind:key="index" v-for="(key, index) in processes">
                    <td @click="goToSteps(key.name)"><a class="text-body font-weight-bold" href="javascript:void(0)">{{key.name}}</a></td>
                    <td @click="goToSteps(key.name)">
                        <a v-if="key.truck_no" class="text-body" href="javascript:void(0)">{{key.truck_no}}</a>
                        <a v-else class="text-body" href="javascript:void(0)">NA</a>
                    </td>
                    <td @click="goToSteps(key.name)">
                      <h5 v-if="key.status === 'InProgress'"><span class="badge badge-warning-lighten"><i class="mdi mdi-coin"></i> In Progress</span></h5>
                      <h5 v-if="key.status === 'Completed'"><span class="badge badge-success-lighten"><i class="mdi mdi-coin"></i> Completed</span></h5>
                    </td>
                    <td @click="goToSteps(key.name)">
                      <span v-if="key.duration === 0">Less than an hour</span>
                      <span v-else-if="key.duration>0">{{key.duration}} Hrs</span>
                    </td>
                    <td @click="goToSteps(key.name)">
                      {{key.created | timeline}}
                    </td>
                    <td @click="goToSteps(key.name)" v-if="key.current_step">{{key.current_step.name}}
                    </td>
                    <td @click="goToSteps(key.name)" v-else>NA</td>
                      <td v-if="currentUser && currentUser.roles.includes('ConsignmentAttester') && key.attester_prior_step && key.current_step" @click="goToSteps(key.name)">{{key.attester_prior_step.name}}
                        <span class="badge font-10 badge-primary-lighten" v-if="key.current_step.order === key.attester_prior_step.order">Ongoing</span>
                        <span class="badge font-10 badge-success-lighten" v-else-if="key.current_step.order > key.attester_prior_step.order">Signed</span>
                        <span class="badge font-10 badge-danger-lighten" v-else>Awaiting</span>
                      </td>
                      <td v-else-if="currentUser.roles.includes('ConsignmentAttester')">NA</td>
                    <td><a class="font-weight-semibold" target="_blank" v-bind:href="$BLOCK_EXPLORER+'/provenance/'+key.process">
                        <i class="mdi mdi-vector-link"></i>&nbsp;View on Blockchain</a></td>
                    <td class="table-action">
                      <router-link :to="{name: 'print-consignment', params: {consignmentid: key.name}}" class="action-icon" title="Print">
                        <i class="mdi mdi-qrcode"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <template v-else-if="removeLoader">
              <h4 class="card-title text-muted text-center mt-lg-4">No records found.</h4>
              <h5 class="card-title text-muted text-center mb-lg-4">Change search criteria and try
                again</h5>
            </template>
          </div> <!-- end card-body-->
          <div class="card-footer">
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-auto p-t-5">
                    <select @change="per_pageRecordChange()" aria-controls="lang_opt" class="form-control custom-select" name="lang_opt_length" v-model="per_page">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <label class="d-none d-sm-block col-form-label">Records per page</label>
                </div>
              </div>
              <div class="col-6">
                <paginate :click-handler="pageHandler" :container-class="'pagination float-right'" :margin-pages="2" :next-class="'page-link'" :next-text="'Next'" :page-class="'page-link'" :page-count=pageCount :page-range="1" :prev-class="'page-link'" :prev-text="'Prev'">
                </paginate>
              </div>
            </div>
          </div>
        </div> <!-- end card-->
      </div> <!-- end col -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../../../event-bus";
import Paginate from 'vuejs-paginate';
import _ from 'lodash';
import moment from "moment";

export default {
  name: "Processes",
  computed: {
    ...mapGetters(["currentUser"])
  },
  components: { Paginate },
  data() {
    return {
      pageCount: 1,
      per_page: 5,
      total: 0,
      filter: {
        search: null,
        status: null,
        attribute_value: null,
        from_date: null,
        to_date: null
      },
      attestors: [],
      current_step: 0,
      isFirstStepAttestor: false,
      processes: [],
      user: {
        mill_id: null
      },
      steps: [],
      selected_step: null,
      attributes: [],
      selected_attribute: null,
      value: true,
      opens: 'right',
      removeLoader: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
        await this.getProcessDefinitionSteps();
        await this.getProcesses(1)
    },
    change(e) {
      let from_date = new Date(e[0].toDate());
      let to_date = new Date(e[1].toDate());
      this.filter.from_date = [
        from_date.getFullYear(),
        '-', ('0' + (from_date.getMonth() + 1)).slice(-2),
        '-', ('0' + from_date.getDate()).slice(-2)].join('');
      this.filter.to_date = [
        to_date.getFullYear(),
        '-', ('0' + (to_date.getMonth() + 1)).slice(-2),
        '-', ('0' + to_date.getDate()).slice(-2)].join('');
      this.getProcesses(1);
    },
    async getProcesses(page) {
      try {
          EventBus.$emit('openLoader');
        this.filter = Object.keys(this.filter)
          .filter((k) => this.filter[k] != "")
          .reduce((a, k) => ({ ...a, [k]: this.filter[k] }), {});
          let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
              params: {
                  page: page - 1,
                  per_page: this.per_page,
                  fetch: {
                      attributes: [{
                          attribute_name: "Truck No.",
                          attribute_step: 0
                      }]
                  },
                  ...this.filter,
                  process_type: 'Consignment'
              }
          });
        this.total = data.total;
        let processes = data.processes.filter(p=> p.name.indexOf('CON') !== -1)
        this.setPageCount();
        this.arrangeProcesses(processes);
      } catch (e) {
      } finally {
          EventBus.$emit('closeLoader');
        this.removeLoader = true;
      }
    },
    setPageCount() {
      this.pageCount = this.total <= this.per_page ? 1 : Math.ceil(this.total / this.per_page);
    },
    async getProcessDefinitionSteps() {
      try {
        let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/process_definition_steps`, {
          params: {
            page: 0,
            per_page: 100,
            process_type: 'Consignment'
          }
        });
        this.steps = data.process_definition_steps;
        let found = data.process_definition_steps.find(ts => ts.order === 0);
          let order_0_attestors = found ? found.attestors : [];
        this.isFirstStepAttestor = order_0_attestors.length>0 ? order_0_attestors.findIndex(a => a.did === this.currentUser.did) != -1 : false;
      } catch (e) {
        console.error(e);
      }
    },
    arrangeProcesses(processes) {
      if (processes) {
          let that = this;
          let attester_steps = this.steps.filter(s=> s.attestors.some(function (sa) {
            return sa.did === that.currentUser.did
          }));
          processes = processes.map(p=> {
                  let found = attester_steps.find(as => as.order >= p.current_step);
                  p.truck_no = p.attributes.length>0?p.attributes[0][2].fact.value:null;
                  return {
                      ...p,
                      duration: p.completed_at ? Math.round(moment.duration(moment(p.completed_at).diff(p.created)).asHours())
                              : Math.round(moment.duration(moment().diff(p.created)).asHours()),
                      current_step: this.steps.find(s => s.order === p.current_step),
                      attester_prior_step: found ? found : attester_steps[attester_steps.length-1]
                  }
              });
          this.processes = processes;
      }
    },
    async getAttributes() {
      if (this.selected_step) {
        try {
          let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/process_definition_step/${this.selected_step.process_definition_step}/attributes`, {
            params: {
              page: 0,
              per_page: 100,
                process_type: 'Consignment'
            }
          });
          this.attributes = data.process_definition_step_attributes;
        } catch (e) {
          console.error(e);
        }
      }
    },
    async createProcess() {
      let d = new Date();
      let y = d.getFullYear();
      let m = d.getMonth();
      let cons_no = `CON-${y}-${m}-${(this.total + 1).toString().padStart(7, '0')}`;
      try {
        EventBus.$emit('openLoader');
        await this.$http.post(`mill/${this.currentUser.mill_id}/processes/new`, {
          name: cons_no,
            process_type: 'Consignment'
        });
        await this.$router.push({ name: 'steps', params: { consignmentid: cons_no } });
      } catch (e) {
        this.$toastr.e('Process creation failed', 'Failed');
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    goToSteps(processid) {
      this.$router.push({ name: 'steps', params: { consignmentid: processid } });
    },
    per_pageRecordChange() {
      this.getProcesses(1);
    },
    pageHandler(pageNum) {
      this.getProcesses(pageNum);
    },
    resetFilter() {
      this.filter = {
        search: null,
        status: null,
        attribute_value: null,
        from_date: null,
        to_date: null
      };
      this.selected_step = null;
      this.selected_attribute = null;
      this.getProcesses(1);
    }
  }
}
</script>

<style>
.page-link.active {
  z-index: 2;
  background-color: #3788fc;
  border-color: #3788fc;
}

.page-link.active a {
  color: #fff;
}

.mdrp__activator .activator-wrapper .text-field {
  padding: 0.45rem 0.9rem !important;
  font-size: 0.875rem !important;
  color: #4f5467 !important;
  border: 1px solid #e9ecef !important;
  border-radius: 2px !important;
  width: 220px !important;
}
</style>